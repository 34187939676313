import _ from 'lodash';
import * as React from 'react';

export interface PaginationLinksForSeoProps {
  currentPage: number;
  totalPages: number;
  nextPrevLinks: string[];
}

function getHrefNode(link: string) {
  return (
    <li>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a href={link} data-hook="product-list-pagination-link-seo-link" />
    </li>
  );
}

export const PaginationLinksForSeo: React.FunctionComponent<PaginationLinksForSeoProps> = (props) => {
  const {nextPrevLinks} = props;

  return (
    <ul data-hook="product-list-pagination-seo" style={{display: 'none'}}>
      {_.map(nextPrevLinks, (link) => getHrefNode(link))}
    </ul>
  );
};
